<script>
  const handleClick = async (event) => {
    event.preventDefault(); // Prevent the default link behavior

    const body = {
      created_at: new Date().toISOString(),
      action: 'Discord click',
      userAgent: navigator.userAgent
    };

    console.log('calling actionTaken API and saving');

    const res = await fetch('/api/actionTaken', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (res.ok) {
      console.log('success');
      window.location.href = 'https://discord.gg/qcR5zTbJzg';
    } else {
      console.error('Failed to save action');
    }
  };
</script>

<p>
  Join our <a
    href="https://discord.gg/qcR5zTbJzg"
    on:click={handleClick}
    aria-label="Join our Discord to share your charts, ask questions, or collaborate with fellow developers!"
    >Discord</a>
  to share your charts, ask questions, or collaborate with fellow developers!
</p>
